


































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import AppListItemAddress from '@/components/AppListItemAddress.vue';
import PICKUPS from '@/graphql/queries/PickupLocations.graphql';
import PICKUP_DELETE from '@/graphql/mutations/PickupLocationDelete.graphql';
import { PickupLocation } from '@/types/schema';

export default Vue.extend({
  name: 'AdminFarms',
  components: {
    AppMain,
    AppEmpty,
    AppPanel,
    AppPanelItem,
    AppListItemAddress,
  },
  data() {
    return {
      pickups: [] as PickupLocation[],
    };
  },
  methods: {
    async deletePickup(id: PickupLocation['id']) {
      if (!confirm("Are you sure? This action can't be undone.")) return;

      try {
        await this.$apollo.mutate({
          mutation: PICKUP_DELETE,
          variables: {
            input: { id },
          },
        });
        this.$apollo.queries.pickups.refetch();
        this.$notify({
          text: 'Farm deleted!',
          type: 'success',
          duration: 6000,
        });
      } catch (error) {
        this.$notify({
          text: error.graphQLErrors[0].message,
          type: 'error',
          duration: 6000,
        });
      }
    },
  },
  apollo: {
    pickups: {
      query: PICKUPS,
      fetchPolicy: 'cache-and-network',
    },
  },
});
